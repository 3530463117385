import { Link } from "gatsby"
import React from "react"
import * as data from "../assets/js/data"
import logo from "../assets/images/Welcome Church Logo.jpeg"

const Navbar = ({ handleMenuToggle }) => {
  const topics = data.topicCopy

  return (
    <ul className="nav__list collapsible__content">
      <li className="nav__item" onClick={handleMenuToggle}>
        <a href="https://welcomechurch.ca/" target="_blank" rel="noreferrer">
          <img className="nav__logo" src={logo} alt="Welcome Church logo" />
        </a>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to="/welcome/">We Are Called to Welcome</Link>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to="/sponsorship/">Refugee Sponsorship</Link>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to="/claimants/">Refugee Claimants in Canada</Link>
      </li>
      <li className="nav__item nav__dropdown" onClick={handleMenuToggle}>
        <Link to="/settling/">Settling in Canada</Link>
        <div className="nav__dropdown-content">
          {Object.entries(topics).map(([id, topic]) => (
            <a
              key={id}
              href={`/settling/${id}/`}
              target="_blank"
              rel="noreferrer"
            >
              {topic.title}
            </a>
          ))}
        </div>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to="/map/">Resource Map</Link>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <Link to="/faq/">FAQ</Link>
      </li>
      <li className="nav__item" onClick={handleMenuToggle}>
        <a
          href="mailto:info@welcomechurch.ca?subject=CanRefugee%20Enquiry"
          target="_blank"
          rel="noreferrer"
        >
          Contact
        </a>
      </li>
    </ul>
  )
}

export default Navbar
