export const topicCopy = {
  settlement_agencies: {
    title: "Settlement Agencies",
  },
  housing: {
    title: "Housing",
  },
  health_and_medical: {
    title: "Health & Medical",
  },
  education: {
    title: "Education",
  },
  finance: {
    title: "Finance",
  },
  transportation: {
    title: "Transportation",
  },
  employment: {
    title: "Employment",
  },
  community: {
    title: "Community",
  },
  financial_aid: {
    title: "Financial Aid",
  },
  government: {
    title: "Government",
  },
  legal_aid: {
    title: "Legal Aid",
  },
  emergency: {
    title: "Emergency & Crisis",
  },
}

export const topicLinks = {
  settlement_agencies: [
    {
      text: "Find newcomer services near you",
      url: "https://ircc.canada.ca/english/newcomers/services/index.asp",
    },
    {
      text:
        "Find a Resettlement Assistance Program Service Provider Organization near you",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/government-assisted-refugee-program/providers.html",
    },
    {
      text: "Find a settlement agency in Ontario",
      url: "https://www.ontario.ca/page/getting-settled-ontario",
    },
    {
      text: "Settlement services in British Columbia",
      url:
        "https://www.welcomebc.ca/Start-Your-Life-in-B-C/Settlement-Services",
    },
    {
      text: "Find settlement services in Saskatchewan",
      url:
        "https://www.saskatchewan.ca/residents/moving-to-saskatchewan/live-in-saskatchewan/language-training-and-settlement-services/settlement-services#settlement-services",
    },
    {
      text: "Settlement services in Manitoba",
      url: "https://www.icmanitoba.com/services/settlement/",
    },
    {
      text: "Find immigrant settlement agencies in New Brunswick",
      url:
        "https://www.welcomenb.ca/content/wel-bien/en/LivingSettling/content/SettlementAgencies.html",
    },
    {
      text: "Find settlement service providers in Nova Scotia",
      url: "https://novascotiaimmigration.com/live-here/settlement-providers/",
    },
    {
      text:
        "Use the Resource Map to look for some settlement agencies in your area",
      url: "https://www.canrefugee.ca/map/?resourcetype=settlementagency",
    },
  ],
  housing: [
    {
      text: "Rental Search Sites",
    },
    {
      text: "Realtor.ca",
      url: "https://www.realtor.ca/",
    },
    {
      text: "Kijiji",
      url: "https://www.kijiji.ca/b-apartments-condos/canada/c37l0",
    },
    {
      text: "Facebook Marketplace",
      url: "https://www.facebook.com/marketplace/category/propertyrentals/",
    },
    {
      text: "Zumper",
      url: "https://www.zumper.com/",
    },
    {
      text: "Basic rental info",
      url: "https://settlement.org/ontario/housing/rent-a-home/basics/",
    },
    {
      text: "Affordable Housing",
    },
    {
      text: "Affordable Housing Programs across Canada",
      url:
        "https://www.cmhc-schl.gc.ca/en/professionals/industry-innovation-and-leadership/industry-expertise/affordable-housing/develop-affordable-housing/provincial-territorial-programs-programs",
    },
    {
      text: "Quebec affordable housing",
      url:
        "https://drive.google.com/file/d/1fDxV14Fj9AmavaTgySYvJ6WszCeHhukJ/view?usp=sharing",
    },
    {
      text: "Subsidized Housing",
      url: "https://settlement.org/ontario/housing/#SUBHO",
    },
    {
      text: "Emergency Shelters",
    },
    {
      text: "What are emergency shelters",
      url: "https://settlement.org/ontario/housing/#TERMHO",
    },
    {
      text: "Refugee-specific shelters",
      url: "https://tinyurl.com/RefugeeHousesInCanada",
    },
    {
      text: "Use the Resource Map for different shelter locations",
      url: "https://www.canrefugee.ca/map/",
    },
    {
      text: "Laws / Rights / Responsibilities",
    },
    {
      text: "Tenant Rights",
      url:
        "https://settlement.org/ontario/housing/rent-a-home/tenant-rights-and-responsibilities/what-are-my-rights-as-a-tenant/",
    },
    {
      text: "Tenant responsibilities",
      url:
        "https://settlement.org/ontario/housing/rent-a-home/tenant-rights-and-responsibilities/what-are-my-responsibilities-as-a-tenant/",
    },
    {
      text: "Housing laws with free booklet downloads",
      url:
        "https://www.cleo.on.ca/en/resources-and-publications/pubs?language=en&field_legal_topic_tid_i18n=89",
    },
    {
      text: "Rental scam",
      url:
        "https://www.canada.ca/en/competition-bureau/news/2018/08/rental-scam-no-room-for-error.html",
    },
  ],
  health_and_medical: [
    {
      text: "Information on Health Program in different provinces",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/health-care.html",
    },
    {
      text: "Interim Federal Health Program",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/health-care/interim-federal-health-program/coverage-summary.html",
    },
    {
      text: "Emergency phone number 911",
      url:
        "https://www.canada.ca/en/public-health/services/emergency-preparedness/using-9-1-1.html",
    },
    {
      text: "Apply for OHIP card in Ontario",
      url: "http://www.ontario.ca/page/apply-ohip-and-get-health-card",
    },
    {
      text: "Telehealth options by province",
      url: "https://www.finder.com/ca/telehealth",
    },
    {
      text: "Mental Health",
    },

    {
      text: "Suicide Prevention Services",
      url: "Canada Suicide Prevention Service | Crisis Services Canada",
    },
    {
      text: "Mental health crisis line",
      url:
        "https://www.canada.ca/en/public-health/services/mental-health-services/mental-health-get-help.html",
    },
    {
      text: "Canadian Mental Health Association",
      url: "https://cmha.ca/find-help/find-cmha-in-your-area/",
    },
    {
      text: "What is PTSD",
      url:
        "http://www.rstp.ca/wp-content/uploads/2016/06/Post-Traumatic-Stress-Disorder-PTSD-Fact-Sheet-1.pdf",
    },
    {
      text: "Where can I get help with my mental health?",
      url:
        "https://settlement.org/ontario/health/mental-health-and-addiction/basics/where-can-i-get-help-with-my-mental-health/",
    },
  ],
  education: [
    {
      text: "Education in different provinces",
      url: "https://www.cmec.ca/299/education-in-canada-an-overview/index.html",
    },
    {
      text: "Education in Canada: What it's like?",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/education/types-school.html",
    },
    {
      text: "Ontario's education system",
      url: "https://peopleforeducation.ca/public-education-in-ontario/",
    },
    {
      text: "Vaccinations for school-aged children",
      url:
        "https://www.canada.ca/en/public-health/services/provincial-territorial-immunization-information/provincial-territorial-routine-vaccination-programs-infants-children.html",
    },
    {
      text: "Post-secondary education",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/education/types-school/post-secondary.html",
    },
    {
      text: "OSAP",
      url: "https://www.ontario.ca/page/learn-about-osap",
    },
    {
      text: "Ontario Works Training Programs in Toronto",
      url:
        "https://www.toronto.ca/community-people/employment-social-support/employment-support/training-courses/ontario-works-training-programs-in-toronto/",
    },
    {
      text:
        "Ontario helping more young people start careers in the skilled trades",
      url: "https://settlement.org/ontario/housing/#SUBHO",
    },
    {
      text: "Job and career training - BC",
      url:
        "https://www2.gov.bc.ca/gov/content/education-training/job-career-training",
    },
    {
      text: "Adult education",
      url: "https://settlement.org/ontario/education/#AEDU",
    },
  ],
  finance: [
    {
      text: "Banking in Canada",
      url:
        "https://www.canada.ca/en/financial-consumer-agency/services/banking.html",
    },
    {
      text: "How credit card works?",
      url:
        "https://www.canada.ca/en/financial-consumer-agency/services/credit-cards/credit-card-work.html",
    },
    {
      text: "How to avoid late fees for credit card payments?",
      url:
        "https://www.cibc.com/en/personal-banking/credit-cards/articles/prevent-late-payments.html",
    },
    {
      text: "How to budget?",
      url:
        "https://itools-ioutils.fcac-acfc.gc.ca/BP-PB/budget-planner?utm_campaign=fcac-acfc-makechangethatcounts-21-22&utm_medium=sem&utm_source=ggl&utm_content=ad-text-en&utm_term=how%20to%20make%20a%20budget&adv=2122-204928&id_campaign=16150609154&id_source=133276599099&id_content=581041971791&gclid=CjwKCAjwiuuRBhBvEiwAFXKaNB_AWQq4xjQD22sv_OA0Hhf1kbOTo-CZOJK41dsgVa8FZDA2nSX6_RoC3XMQAvD_BwE&gclsrc=aw.ds",
    },
    {
      text: "Shopping tips?",
      url: "https://www.mymoneycoach.ca/saving-money/saving-on-groceries",
    },
    {
      text: "Connecting Families - internet service",
      url:
        "https://www.canada.ca/en/innovation-science-economic-development/news/2021/08/affordable-high-speed-internet-for-low-income-seniors-and-families.html",
    },
    {
      text: "Affordable cellphone plans",
      url: "https://www.whistleout.ca/CellPhones/Guides/best-cell-phone-plans",
    },
    {
      text: "Electricity support program",
      url: "https://ontarioelectricitysupport.ca/",
    },
    {
      text: "Warning of fraud",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/protect-fraud.html#shr-pg0",
    },
    {
      text:
        "Fraud Targeting newcomers: What kinds of fraud should newcomers to Canada watch out for? - Canada.ca",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/protect-fraud/newcomers.html",
    },
    {
      text: "File income tax",
    },
    {
      text: "Learn about personal income tax by CRA",
      url:
        "https://www.canada.ca/en/revenue-agency/services/tax/individuals/educational-programs.html?utm_campaign=not-applicable&utm_medium=vanity-url&utm_source=canada-ca_learn-about-taxes",
    },
    {
      text: "Free tax Clinics to help file tax returns",
      url:
        "https://www.canada.ca/en/revenue-agency/campaigns/free-tax-help.html",
    },
  ],
  transportation: [
    {
      text: "Union-Pearson Express (UP Express)",
      url: "https://www.upexpress.com/",
    },
    {
      text:
        "Transportation in Canada by air, rail, bus, ferry, car, public transit taxi, walking and cycling",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/new-life-canada/driving/other-transportation.html",
    },
    {
      text: "Transit Assistance Programs",
    },
    {
      text: "Affordable Transit Program in Ontario",
      url:
        "https://rocketmanapp.com/blog/low-income-transit-passes-for-ontario/",
    },
    {
      text: "Reduced-cost public transit pass in British Columbia",
      url:
        "https://www2.gov.bc.ca/gov/content/transportation/passenger-travel/buses-taxis-limos/bus-pass/seniors",
    },
    {
      text: "Driver's Licence",
    },
    {
      text: "Ontario",
      url: "http://www.ontario.ca/page/get-g-drivers-licence-new-drivers",
    },
    {
      text:
        "For current licence-holders wishing to switch to an Ontario licence",
      url: "http://www.ontario.ca/page/exchange-out-province-drivers-licence",
    },
    {
      text:
        "British Columbia - The Insurance Corporation of British Columbia (ICBC) Driver licensing and ID",
      url: "https://www.icbc.com/driver-licensing/Pages/Default.aspx",
    },
    {
      text: "For current licence-holders wishing to switch to a B.C. licence",
      url:
        "https://www.icbc.com/driver-licensing/moving-bc/Pages/Moving-from-another-country.aspx",
    },
    {
      text: "Alberta",
      url: "https://www.alberta.ca/get-drivers-licence.aspx",
    },
    {
      text:
        "For current licence-holders wishing to switch to an Alberta licence",
      url: "https://www.alberta.ca/exchange-non-alberta-licences.aspx",
    },
    {
      text: "6 Steps to Buying a Used Car",
      url: "https://www.guardtree.ca/blogs/news/buying-a-used-car-steps",
    },
    {
      text: "Auto Insurance Bureau of Canada",
    },
    {
      text: "British Columbia",
      url: "http://www.ibc.ca/bc/auto",
    },
    {
      text: "Ontario",
      url: "http://www.ibc.ca/on/auto",
    },
    {
      text: "Alberta",
      url: "http://www.ibc.ca/ab/auto",
    },
    {
      text: "Saskatchewan",
      url: "http://www.ibc.ca/sk/auto",
    },
    {
      text: "Manitoba",
      url: "http://www.ibc.ca/mb/auto",
    },
    {
      text: "Quebec",
      url: "http://www.ibc.ca/qc/auto",
    },
    {
      text: "New Brunswick",
      url: "http://www.ibc.ca/nb/auto",
    },
    {
      text: "Nova Scotia",
      url: "http://www.ibc.ca/ns/auto",
    },
    {
      text: "Prince Edward Island",
      url: "http://www.ibc.ca/pe/auto",
    },
    {
      text: "Newfoundland and Labrador",
      url: "http://www.ibc.ca/nl/auto",
    },
    {
      text: "Auto insurance Q & A",
      url: "http://assets.ibc.ca/Documents/Brochures/Car-Insurance-Q-and-A.pdf",
    },
    {
      text: "Public transit in Ontario",
    },
    {
      text: "Toronto Transit Commission (TTC)",
      url: "https://www.ttc.ca/",
    },
    {
      text: "Go Transit",
      url: "https://www.gotransit.com/en/",
    },
    {
      text: "Union-Pearson Express (UP Express)",
      url: "https://www.upexpress.com/",
    },
    {
      text: "Brampton Transit",
      url: "https://www.brampton.ca/en/residents/transit/Pages/Home.aspx",
    },
    {
      text: "Mississauga Transit (MiWAY)",
      url: "https://www.mississauga.ca/miway-transit/",
    },
    {
      text: "Durham Region Transit (DRT)",
      url: "https://www.durhamregiontransit.com/en/index.aspx",
    },
    {
      text: "Grand River Transit (GRT)",
      url: "https://www.grt.ca/en/index.aspx",
    },
    {
      text: "Niagara Region Transit",
      url: "https://www.niagararegion.ca/transit/default.aspx",
    },
    {
      text: "NRT OnDemand",
      url: "https://niagararegion.ca/transit/on-demand/default.aspx",
    },
    {
      text: "Niagara Falls Transit",
      url: "https://niagarafalls.ca/living/transit/default.aspx",
    },
    {
      text: "St. Catharines Transit",
      url: "https://yourbus.com",
    },
    {
      text: "City of Ottawa (OC Transpo)",
      url: "https://www.octranspo.com/en/",
    },
    {
      text: "City of Kingston",
      url: "https://www.cityofkingston.ca/residents/transit",
    },
    {
      text: "City of Peterborough",
      url: "https://www.peterborough.ca/en/city-services/transit.aspx",
    },
    {
      text: "City of Hamilton",
      url: "https://www.hamilton.ca/hsr-bus-schedules-fares",
    },
    {
      text: "City of Guelph",
      url: "https://guelph.ca/living/getting-around/bus/",
    },
    {
      text: "City of London",
      url: "https://www.londontransit.ca/",
    },
    {
      text: "City of Windsor",
      url:
        "https://www.citywindsor.ca/residents/transitwindsor/Pages/Transit-Windsor.aspx",
    },
    {
      text: "British Columbia",
    },
    {
      text: "TransLink",
      url: "https://www.translink.ca/",
    },
    {
      text: "BC Transit",
      url: "https://www.bctransit.com/victoria/home",
    },
    {
      text: "Alberta",
    },
    {
      text: "Calgary Transit",
      url: "https://www.calgarytransit.com/content/transit/en/home/home.html",
    },
    {
      text: "Edmonton transit service (ETS)",
      url: "https://www.edmonton.ca/edmonton-transit-system-ets",
    },
    {
      text: "Saskatchewan",
    },
    {
      text: "Saskatoon Transit",
      url: "https://transit.saskatoon.ca/",
    },
    {
      text: "Regina Transit",
      url: "https://www.regina.ca/transportation-roads-parking/transit/",
    },
    {
      text: "Manitoba",
    },
    {
      text: "Winnipeg Transit",
      url: "https://winnipegtransit.com/en",
    },
    {
      text: "Quebec",
    },
    {
      text: "STM",
      url: "https://m.stm.info/en/messages",
    },
    {
      text: "City of Laval (STL)",
      url: "https://stlaval.ca/home",
    },
    {
      text: "Urban agglomeration of Longueuil (RTL)",
      url: "https://www.rtl-longueuil.qc.ca/en-CA/home-page/",
    },
    {
      text: "Reseau de transport de la Capitale (RTC)",
      url: "https://www.rtcquebec.ca/en",
    },
    {
      text: "Atlantic region",
    },
    {
      text: "Fredericton Transit",
      url: "https://www.fredericton.ca/en/transit",
    },
    {
      text: "Halifax Transit",
      url: "https://www.halifax.ca/transportation/halifax-transit",
    },
    {
      text: "T3 Transit",
      url: "https://www.t3transit.ca/",
    },
    {
      text: "Metrobus transit",
      url: "https://www.metrobus.com/home/",
    },
  ],
  employment: [
    {
      text: "Looking for Jobs in Canada",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/prepare-life-canada/prepare-work/look-jobs.html",
    },
    {
      text: "Apply for a Job",
      url: "https://helpingnewcomerswork.ca/applying-for-a-job/",
    },
    {
      text: "Federal Internship for Newcomer (FIN program)",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/new-immigrants/prepare-life-canada/prepare-work/federal-internship.html",
    },
    {
      text: "Skills Training",
      url: "https://www.jobbank.gc.ca/career-planning/training",
    },
    {
      text: "Provincial Apprenticeship Program",
      url:
        "https://www.canada.ca/en/employment-social-development/services/apprentices/provinces-territories.html",
    },
    {
      text: "Ontario Bridge Training Program",
      url:
        "https://www.ontario.ca/page/adult-learning-ontario-bridge-training-program",
    },
    {
      text: "Various Training Programs in Ontario",
      url:
        "https://helpingnewcomerswork.ca/training-programs-in-various-occupations/",
    },
    {
      text: "Job Search Sites",
    },
    { text: "Government job bank", url: "https://www.jobbank.gc.ca/" },
    {
      text: "Indeed",
      url: "https://www.indeed.ca/",
    },
    {
      text: "Workopolis",
      url: "https://www.workopolis.com/",
    },
    {
      text: "Monster",
      url: "https://monster.ca/",
    },
    {
      text: "Linkedin",
      url: "https://ca.linkedin.com/",
    },
    {
      text: "Charity Village",
      url: "https://charityvillage.com/",
    },
    {
      text: "Job Bank Canada Services",
    },
    {
      text: "Find a job in Canada as a newcomer",
      url: "https://www.jobbank.gc.ca/findajob/newcomers",
    },
    {
      text: "How to write a good resume",
      url: "https://www.jobbank.gc.ca/findajob/resources/write-good-resume",
    },
    {
      text: "Resume builder",
      url: "https://www.jobbank.gc.ca/findajob/resume-builder",
    },
  ],
  community: [
    {
      text: "What services can I get at the library?",
      url:
        "https://settlement.org/ontario/community/libraries-and-learning/libraries/what-services-can-i-get-at-the-library/",
    },
    {
      text:
        "EarlyON Child and Family Centres offer free programs to parent/caregivers and their children from birth to six years of age",
      url:
        "https://www.toronto.ca/community-people/children-programs-activities/child-family-programs-and-groups/child-family-programs/",
    },
    {
      text: "Community Centres in Canada - recreation, arts, sports and more",
      url:
        "https://www.mycism.com/2019/11/community-centres-in-canada-recreation-arts-sports-and-more/",
    },
    {
      text: "Churches in Vancouver supporting the community",
      url:
        "https://churchforvancouver.ca/around-town-churches-supporting-community-charities-need-a-hand/",
    },
    {
      text: "The Salvation Army social services",
      url:
        "https://salvationarmy.ca/what-we-do/in-your-community/social-services/",
    },
    {
      text:
        "Gibson Centre in conjunction with Toronto Christian Community Church in Markham, offers services and programs for people of all ages",
      url: "https://105gibson.com/",
    },
    {
      text: "12 Places to shop for affordable clothes in Toronto",
      url: "https://www.styledemocracy.com/affordable-shop-clothes-toronto/",
    },
    {
      text: "17 ways to get free diapers in Canada",
      url: "https://cansumer.ca/free-diapers-canada/",
    },
    {
      text: "Food bank",
      url:
        "https://settlement.org/ontario/health/nutrition-and-healthy-living/healthy-living-and-nutrition/what-is-a-food-bank/",
    },
    {
      text: "Community hub",
      url:
        "https://settlement.org/ontario/health/community-and-public-health/public-and-community-health/what-is-a-community-hub/",
    },
  ],
  financial_aid: [
    {
      text: "GST / HST credit",
      url:
        "https://www.canada.ca/en/revenue-agency/services/child-family-benefits/gsthstc-apply.html",
    },
    {
      text: "Provincial and territorial programs",
      url:
        "https://www.canada.ca/en/revenue-agency/services/child-family-benefits/provincial-territorial-programs.html",
    },
    {
      text: "Child and Family Benefit Overview",
      url:
        "https://www.canada.ca/en/revenue-agency/services/child-family-benefits.html",
    },
    {
      text: "Canada Learning Bond",
      url:
        "• https://www.canada.ca/en/employment-social-development/services/learning-bond.html",
    },
    {
      text: "Disability benefits",
      url: "https://www.canada.ca/en/services/benefits/disability.html",
    },
    {
      text: "Employment Insurance",
      url:
        "https://www.canada.ca/en/services/benefits/ei/ei-regular-benefit.html",
    },
    {
      text: "Tools to find government benefits",
    },
    {
      text: "Government Benefit Finder",
      url:
        "https://srv138.services.gc.ca/daf/s/38c79f5b-6e85-426b-b74d-9fdccde08d72?GoCTemplateCulture=en-CA",
    },
    {
      text: "Government Benefit guide by Prosper Canada",
      url: "https://benefitswayfinder.org/",
    },
    {
      text: "Benefits for families with children",
    },
    {
      text: "Canada Child Benefit (not for refugee claimants)",
      url:
        "• https://www.canada.ca/en/revenue-agency/services/child-family-benefits/canada-child-benefit-overview.html#nt",
    },
    {
      text: "Child care subsidies",
    },
    {
      text: "Ontario child care subsidy",
      url: "https://www.ontario.ca/page/child-care-subsidies#section-0",
    },
    {
      text: "BC child care subsidy",
      url:
        "https://www2.gov.bc.ca/gov/content/family-social-supports/caring-for-young-children/child-care-funding/child-care-benefit",
    },
  ],
  government: [
    {
      text: "Canada Identification Documents",
    },
    {
      text:
        "Apply for Social Insurance Number (SIN) - Social Insurance Number - Apply - Canada.ca",
      url:
        "https://www.canada.ca/en/employment-social-development/services/sin/apply.html",
    },
    {
      text: "If you do not have a driver's licence, apply for a photo ID card",
    },
    {
      text: "Ontario Photo Card",
      url: "https://www.ontario.ca/page/ontario-photo-card",
    },
    {
      text: "British Columbia ID Card",
      url:
        "https://www.icbc.com/driver-licensing/getting-licensed/Pages/Apply-for-a-BCID.aspx?step=1",
    },
    {
      text: "Alberta ID Card",
      url: "https://www.alberta.ca/get-id-card.aspx",
    },
    {
      text: "Saskatchewan Photo ID card: Photo ID - SGI",
      url: "https://www.sgi.sk.ca/photo-id",
    },
    {
      text: "Manitoba ID Card: idcards.pdf (mpi.mb.ca)",
      url: "https://www.mpi.mb.ca/documents/idcards.pdf",
    },
    {
      text: "New Brunswick Photo ID Card: Photo ID Card (gnb.ca)",
      url:
        "https://www2.gnb.ca/content/gnb/en/services/services_renderer.12535.Photo_ID_Card.html",
    },
    {
      text: "Nova Scotia ID Card",
      url: "https://novascotia.ca/sns/paal/RMV/paal275.asp",
    },
    {
      text:
        "PEI Photo ID Card: Photographic Identification Card (Voluntary ID) | Government of Prince Edward Island",
      url:
        "https://www.princeedwardisland.ca/en/information/transportation-and-infrastructure/photographic-identification-card-voluntary-id",
    },
    {
      text: "Newfoundland ID card",
      url:
        "https://www.gov.nl.ca/motorregistration/new-drivers/photo-identification-card/",
    },
    {
      text: "Income Tax Returns",
    },
    {
      text: "Who should file a tax return",
      url:
        "https://www.canada.ca/en/services/taxes/income-tax/personal-income-tax/who-should-file-tax.html",
    },
    {
      text: "Free tax clinics",
      url: "www.cra-arc.gc.ca/volunteer",
    },
    {
      text: "Government related information and services for newcomers",
    },
    {
      text: "Help for sponsored refugees to adjust to life in Canada",
      url:
        "https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/help-within-canada/government-assisted-refugee-program/providers.html",
    },
    {
      text: "Ontario government for newcomers",
      url: "https://www.ontario.ca/page/newcomers",
    },
    {
      text: "Help for settlement in Ontario",
      url: "ttps://settlement.org/about/",
    },
    {
      text: "311 Toronto non-emergency services",
      url: "https://www.toronto.ca/home/311-toronto-at-your-service/",
    },
    {
      text: "Learn about Canadian government",
    },
    {
      text: "Official website of the Government of Canada",
      url: "https://www.canada.ca/en.html",
    },
    {
      text:
        "What you should know about Canada - Welcome to Canada - What you should know,welcome.pdf",
      url:
        "https://www.canada.ca/content/dam/ircc/migration/ircc/english/pdf/pub/welcome.pdf",
    },
    {
      text:
        "Ontario Government Ministry & Services - Government of Ontario | ontario.ca",
      url: "https://www.ontario.ca/page/government-ontario",
    },
  ],
  legal_aid: [
    {
      text: "Legal Aid services for refugee claimants",
      url: "https://www.legalaid.on.ca/services-for-refugee-claimants/",
    },
    {
      text:
        "Free legal service for family violence: Domestic Violence - Legal Aid Ontario",
      url: "https://www.legalaid.on.ca/services/domestic-abuse/",
    },
    {
      text: "Find a lawyer - Law Society Referral Service (LSRS)",
      url: "http://www.findlink.at/lsuc-lrs",
    },
    {
      text: "Step-by-step information about legal problems",
      url: "https://stepstojustice.ca/",
    },
    {
      text:
        "Free legal resources and publications by CLEO (Community Legal Education Ontario)",
      url: "https://www.cleo.on.ca/en",
    },
    {
      text: "Ontario's legal system",
      url: "https://www.ontario.ca/page/ministry-attorney-general",
    },
    {
      text:
        "A not-for-profit service helping people in need of legal expertise",
      url: "http://findlink.at/JusNet",
    },
    {
      text: "Family violence counseling and help",
      url:
        "https://stepstojustice.ca/steps/family-law/2-call-legal-aid-ontario",
    },
    {
      text: "More information about Community Legal Clinics",
      url: "http://www.legalaid.on.ca/en/contact/contact.asp?type=cl",
    },
    {
      text: "How to find a lawyer",
      url: "https://www.probonoontario.org/",
    },
    {
      text: "Getting Legal help",
      url: "http://findlink.at/legaldir",
    },
    {
      text: "How do I apply for legal aid services?",
      url:
        "https://settlement.org/ontario/legal-services/legal-aid-and-community-clinics/legal-aid/how-do-i-apply-for-legal-aid-services/",
    },
    {
      text: "Legal Aid Ontario (LAO)",
      url: "http://findlink.at/lao",
    },
    {
      text: "Law Society Referral Services",
      url: "http://www.findlink.at/lsuc-lrs",
    },
    {
      text: "How to apply for Legal Aid in Ontario",
    },
    {
      text:
        "How to apply for legal aid by LAO: How do I apply for legal aid? - Legal Aid Ontario",
      url: "http://www.legalaid.on.ca/services/how-do-i-apply-for-legal-aid/",
    },
    {
      text:
        "What you need to know about applying for legal aid: How do I apply for legal aid services? (settlement.org)",
      url:
        "https://settlement.org/ontario/legal-services/legal-aid-and-community-clinics/legal-aid/how-do-i-apply-for-legal-aid-services/",
    },
  ],
  emergency: [
    {
      text: "Emergency needing police, ambulance or firefighters",
    },
    {
      text: "Emergency phone number 911",
      url:
        "https://settlement.org/ontario/health/emergency-services/help-in-an-emergency/how-can-i-get-help-in-an-emergency/",
    },
    {
      text: "Health related crisis",
    },
    {
      text:
        "Suicide Prevention Services: Canada Suicide Prevention Service | Crisis Services Canada",
      url: "https://www.crisisservicescanada.ca/en/",
    },
    {
      text:
        "Find a Distress and Crisis Organization near you: Local Resources & Support | Crisis Services Canada",
      url:
        "https://www.crisisservicescanada.ca/en/looking-for-local-resources-support/",
    },
    {
      text:
        "Canada Mental Health Association: If you are in crisis - CMHA National",
      url: "https://cmha.ca/find-help/if-you-are-in-crisis/",
    },
    {
      text:
        "Who can help when there is family violence: Get help with family violence",
      url: "https://www.justice.gc.ca/eng/cj-jp/fv-vf/help-aide.html",
    },
    {
      text: "Assaulted Women Helpline (24/7 telephone crisis line for women)",
      url: "https://www.awhl.org/",
    },
    {
      text: "Domestic Violence Crisis Line by Province / Territory",
      url: "https://sheltersafe.ca/find-help/",
    },
    {
      text: "Food",
    },
    {
      text: "Find a Food Bank near you",
      url: "https://foodbankscanada.ca/find-a-food-bank/",
    },
    {
      text: "Food banks and community food programs in Toronto",
      url:
        "https://www.torontocentralhealthline.ca/listServicesDetailed.aspx?id=10572&region=Toronto",
    },
    {
      text: "Use 211 to locate a food program in your community",
      url: "https://211.ca/",
    },
    {
      text: "Shelters",
    },
    {
      text: "Shelters for Refugee Claimants",
      url: "https://tinyurl.com/RefugeeHousesInCanada",
    },
    {
      text: "Shelters for Women Experiencing Abuse",
      url: "https://sheltersafe.ca/get-help/",
    },
    {
      text:
        "For Homeless Shelters, please use google or 211 to locate one in your area",
      url: "https://211.ca/",
    },
  ],
}

export const topicVideoUrls = {
  settlement_agencies:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqY9f-TFmEFcmUYzIRyWxuv",
  housing:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rr9-nfV2LiJjtBsaJirXEJI",
  health_and_medical:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rr7iaDwqTWZ20De3CEj2xOF",
  education:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rpAFAaDlZwXwR2G-UMp7tm-",
  finance:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqAoovtLHRvkxy2ZM84dCFH",
  transportation:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqFXdBdc7TkL4nxzUaOshk8",
  employment:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rr5VWuPFCkM9NGse36Gldq8",
  community:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqZUqKTCXliqXgEF_hg-K1T",
  financial_aid:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rpzTcOW-wz1q9a1wfoiZDRq",
  government:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rpCWPIPdXCW7ONvDb7Jus7I",
  legal_aid:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqrDIrKNeZvQrZKfqOiWjW_",
  emergency:
    "https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rrecH6spmlj150Y-qVAgezD",
}
