import React from "react"

const Footer = () => {
  return (
    <footer>
      CanRefugee is not responsible for the content or access to the external
      links. Copyright {new Date().getFullYear()} CanRefugee. Powered by
      Kingdomdev
    </footer>
  )
}

export default Footer
