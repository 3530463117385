import React, { useState } from "react"
import { Link } from "gatsby"
import Navbar from "./Navbar"
import { Collapse, Menu } from "../components/Icons"
import logo from "../assets/images/CanRefugee Logo (Stacked).jpg"

const Header = () => {
  const [navClass, setNavClass] = useState("nav collapsible")
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleMenuToggle = () => {
    if (navClass.includes("collapsible--expanded")) {
      setNavClass("nav collapsible")
      setIsMenuOpen(false)
    } else {
      setNavClass("nav collapsible collapsible--expanded")
      setIsMenuOpen(true)
    }
  }

  return (
    <nav className={navClass}>
      <Link to="/">
        <img className="nav__logo" src={logo} alt="CanRefugee logo" />
      </Link>
      {isMenuOpen ? (
        <Collapse
          className="nav__icon nav__toggler"
          onClick={handleMenuToggle}
        />
      ) : (
        <Menu className="nav__icon nav__toggler" onClick={handleMenuToggle} />
      )}
      <Navbar />
    </nav>
  )
}

export default Header
