import React from "react"

const Phone = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="<http://www.w3.org/2000/svg>"
    >
      <path d="M19.23 15.26l-2.54-.29a1.99 1.99 0 00-1.64.57l-1.84 1.84a15.045 15.045 0 01-6.59-6.59l1.85-1.85c.43-.43.64-1.03.57-1.64l-.29-2.52a2.001 2.001 0 00-1.99-1.77H5.03c-1.13 0-2.07.94-2 2.07.53 8.54 7.36 15.36 15.89 15.89 1.13.07 2.07-.87 2.07-2v-1.73c.01-1.01-.75-1.86-1.76-1.98z" />
    </svg>
  )
}

const Collapse = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="<http://www.w3.org/2000/svg>"
      onClick={onClick}
    >
      <path d="M11.29 8.71L6.7 13.3a.996.996 0 101.41 1.41L12 10.83l3.88 3.88a.996.996 0 101.41-1.41L12.7 8.71a.996.996 0 00-1.41 0z" />
    </svg>
  )
}

const Menu = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      xmlns="<http://www.w3.org/2000/svg>"
      onClick={onClick}
    >
      <path d="M4 18h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zm0-5h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1zM3 7c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1z" />
    </svg>
  )
}

const MapMarker = ({ className, fill }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 100 100"
      xmlns="<http://www.w3.org/2000/svg>"
    >
      <path
        d="M81.2,0H18.7c-3.5,0-6.2,2.8-6.2,6.2v62.6c0,3.5,2.8,6.2,6.2,6.2h20l9.5,22.8c1,2.9,2.5,2.9,3.5,0l9.4-22.7h20c3.5,0,6.3-2.8,6.3-6.2V6.2C87.5,2.8,84.7,0,81.2,0z"
        fill={fill}
      />
    </svg>
  )
}

export { Phone, Collapse, Menu, MapMarker }
